
export const GENERAL_ERROR = 'GENERAL_ERROR';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const FS_UPDATE = 'FS_UPDATE';
export const LOGIN_MESSAGE = 'LOGIN_MESSAGE';
export const TOKEN_ERROR = 'Failed to authenticate token.';
export const PASSWORD_REGEX = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9#?!@$%^&*-]).{8,}$');
export const BASE_URL = process.env.API_URL;
export const FS_APP_ID = process.env.FILESTACK_APP;
export const UPLOADCARE_KEY = process.env.UPLOADCARE_KEY;
