import request from 'superagent';
import jwt_decode from "jwt-decode";
import { ToastsStore } from 'react-toasts';
import {
  SET_LANGUAGE,
  BASE_URL,
  LOGIN,
  TOKEN_ERROR,
  LOGOUT,
} from './constants';

const isBrowser = typeof window !== 'undefined';
const noCache = isBrowser ? require('superagent') : undefined;

export function setLanguage(language) {
  return {
    type: SET_LANGUAGE,
    language,
  };
}

export function notifyAction(message = 'Success.', type = 'success') {
  ToastsStore[type](message);
}

export function resetStorage(dispatch) {
  localStorage.removeItem('LMCCAPP');
  localStorage.clear();
  if (dispatch) {
    dispatch({
      type: LOGOUT
    });
  }
}

export function login(cred) {
  return (dispatch) => request.post(`${BASE_URL}/user/login`)
    .use(noCache)
    .send(cred)
    .then(({ body: { data } }) => {
      if (data && !data.err) {
        dispatch({
          type: LOGIN,
          user: data.user || {},
          token: data.token || '',
          fsAppId: data.filestackApp,
        });
      } else if (data && data.err) {
        notifyAction(data.err, 'error');
      }
    })
    .catch(() => {
      notifyAction('Error login in', 'error');
    });
}

export function logout() {
  localStorage.removeItem('LMCCAPP');
  localStorage.clear();

  return (dispatch) => {
    dispatch({
      type: LOGOUT
    });
  };
}

export function getRolesList() {
  return (dispatch, getState) => {
    const { token } = getState().app;
    const decoded = jwt_decode(token);

    return request.get(`${BASE_URL}/roles?t=${decoded.tenant}`)
      .use(noCache)
      .set({ Authorization: token })
      .then(({ body: { data, message } }) => {
        if (message && (message === TOKEN_ERROR)) {
          dispatch({
            type: LOGOUT
          });
          localStorage.clear();
          return [];
        }
        return data;
      })
      .catch(() => {
        notifyAction('Error getting roles', 'error');
      });
  };
}

// IMAGES
export function addFiles(filesList) {
  return (dispatch, getState) => {
    const { token } = getState().app;

    return request.post(`${BASE_URL}/file`)
      .use(noCache)
      .set({ Authorization: token })
      .send({ files: filesList })
      .then(({ body: { data, status, message } }) => {
        if (message && (message === TOKEN_ERROR)) {
          dispatch({
            type: LOGOUT
          });
          localStorage.clear();
          return status;
        }
        notifyAction('Success adding file');
        return data.docs;
      })
      .catch(() => {
        notifyAction('Error adding file', 'error');
      });
  };
}

export function removeFile(url) {
  return (dispatch, getState) => {
    const { token } = getState().app;

    return request.delete(`${BASE_URL}/file`)
      .use(noCache)
      .set({ Authorization: token })
      .send({ url })
      .then(({ body: { data, status, message } }) => {
        if (message && (message === TOKEN_ERROR)) {
          dispatch({
            type: LOGOUT
          });
          localStorage.clear();
          return status;
        }
        notifyAction('Success removing file');
        return data.files;
      })
      .catch(() => {
        notifyAction('Error removing file', 'error');
      });
  };
}

export function getHeader() {
  return (dispatch, getState) => {
    const { token } = getState().app;
    const decoded = jwt_decode(token);

    return request.get(`${BASE_URL}/header?t=${decoded.tenant}`)
      .use(noCache)
      .set({ Authorization: token })
      .then(({ body: { data, message } }) => {
        if (message && (message === TOKEN_ERROR)) {
          return resetStorage(dispatch);
        }

        return data;
      })
      .catch(() => {
        notifyAction('Error getting header', 'error');
      });
  };
}

export function updateHeader(header) {
  return (dispatch, getState) => {
    const { token } = getState().app;
    return request.put(`${BASE_URL}/header`)
      .use(noCache)
      .set({ Authorization: token })
      .send(header)
      .then(({ body: { data, message } }) => {
        if (message && (message === TOKEN_ERROR)) {
          return resetStorage(dispatch);
        }
        notifyAction('Success updating header');
        return data;
      })
      .catch(() => {
        notifyAction('Error updating header', 'error');
      });
  };
}

export function getFooter() {
  return (dispatch, getState) => {
    const { token } = getState().app;
    const decoded = jwt_decode(token);
    return request.get(`${BASE_URL}/footer?t=${decoded.tenant}`)
      .use(noCache)
      .set({ Authorization: token })
      .then(({ body: { data, message } }) => {
        if (message && (message === TOKEN_ERROR)) {
          return resetStorage(dispatch);
        }
        return data;
      })
      .catch(() => {
        notifyAction('Error getting footer', 'error');
      });
  };
}

export function updateFooter(header) {
  return (dispatch, getState) => {
    const { token } = getState().app;
    return request.put(`${BASE_URL}/footer`)
      .use(noCache)
      .set({ Authorization: token })
      .send(header)
      .then(({ body: { data, message } }) => {
        if (message && (message === TOKEN_ERROR)) {
          return resetStorage(dispatch);
        }
        notifyAction('Success updating footer');
        return data;
      })
      .catch(() => {
        notifyAction('Error updating footer', 'error');
      });
  };
}

export function switchFeatured(path, switchValue) {
  return (dispatch, getState) => {
    const { token } = getState().app;

    return request.put(`${BASE_URL}${path}`)
      .use(noCache)
      .set({ Authorization: token })
      .send({
        featured: switchValue,
      })
      .then(({ body: { data, message } }) => {
        if (message && (message === TOKEN_ERROR)) {
          return resetStorage(dispatch);
        }
        notifyAction('Success switching');
        return data;
      })
      .catch(() => {
        notifyAction('Error switching', 'error');
      });
  };
}
