import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/HomePage/Loadable';
import Profile from './containers/Profile/Loadable';
import NotFoundPage from './containers/NotFoundPage/Loadable';
import Login from './containers/Login/Loadable';
import About from './containers/About/Loadable';
import Personnel from './containers/Personnel/Loadable';
import Services from './containers/Services/Loadable';
import Benefits from './containers/Benefits/Loadable';
import Packages from './containers/Packages/Loadable';
import Facts from './containers/Facts/Loadable';
import Volunteering from './containers/Volunteering/Loadable';
import Staff from './containers/Staff/Loadable';
import Company from './containers/Company/Loadable';
import Facilities from './containers/Facilities/Loadable';
import MultiData from './containers/MultiData/Loadable';
import SectionHeader from './containers/SectionHeader/Loadable';

export default (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/dashboard" component={Home} />
    <Route exact path="/profile" component={Profile} />
    <Route exact path="/about" component={About} />
    <Route exact path="/personnel" component={Personnel} />
    <Route exact path="/personnel/create" component={Personnel} />
    <Route exact path="/personnel/:id" component={Personnel} />
    <Route exact path="/personnel/:view/:id" component={Personnel} />
    <Route exact path="/profile/:id" component={Profile} />
    <Route exact path="/company" component={Company} />
    <Route exact path="/services" component={Services} />
    <Route exact path="/benefits" component={Benefits} />
    <Route exact path="/packages" component={Packages} />
    <Route exact path="/facts" component={Facts} />
    <Route exact path="/volunteering" component={Volunteering} />
    <Route exact path="/staff" component={Staff} />
    <Route exact path="/facilities" component={Facilities} />
    <Route exact path="/multi-data" component={MultiData} />
    <Route exact path="/headers" component={SectionHeader} />
    <Route exact path="/*" component={NotFoundPage} />
  </Switch>
);
