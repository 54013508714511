import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import Favicon from 'react-favicon';
import { bindActionCreators } from 'redux';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { isEmpty } from 'lodash';

import Navigation from '../../components/Navigation';
import SubNavigation from '../../components/SubNavigation';
import Routes from '../../routes';

import './style.scss';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    autoBind(this);
  }

  componentDidMount() {
    const {
      location: { pathname }, history, user, navigation
    } = this.props;
    const userPermissions = !isEmpty(user) ? user.roles[0].value : 0;
    const navMatch = navigation.find((item) => item.path === pathname);
    const pagePermissions = navMatch ? navMatch.restrictionLevel : 100;

    if ((isEmpty(user) || (!isEmpty(user) && !user.active)) && (pathname !== '/')) {
      history.push('/');
    }
    if ((!isEmpty(user) && user.active) && (pathname === '/')) {
      if (userPermissions === 1) {
        history.push('/profile');
      } else {
        history.push('/dashboard');
      }
    }

    if (navMatch) {
      if (userPermissions < pagePermissions) {
        history.push('/not-allowed');
      }

      window.scrollTo(0, 0);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { location: { pathname: oldPathname }, navigation } = this.props;
    const { location: { pathname }, history, user } = nextProps;
    const userPermissions = !isEmpty(user) ? user.roles[0].value : 0;
    const navMatch = navigation.find((item) => item.path === pathname);
    const pagePermissions = navMatch ? navMatch.restrictionLevel : 100;

    if ((isEmpty(user) || (!isEmpty(user) && !user.active)) && (pathname !== '/')) {
      history.push('/');
    }
    if ((!isEmpty(user) && user.active) && (pathname === '/')) {
      if (userPermissions === 1) {
        history.push('/profile');
      } else {
        history.push('/dashboard');
      }
    }

    if (pathname !== oldPathname && (history.action === 'PUSH')) {
      if (navMatch) {
        if (userPermissions < pagePermissions) {
          history.push('/not-allowed');
        }

        window.scrollTo(0, 0);
      }
    }
  }

  render() {
    const { location: { pathname } } = this.props;
    const isLogin = pathname === '/';
    return (
      <div className={`app__container ${isLogin ? 'login' : 'content'}`}>
        <Favicon url={'/favicon.ico'} />
        {(pathname !== '/') && <Navigation {...this.props} />}
        <section className="app__content">
          {(pathname !== '/') && <SubNavigation {...this.props} />}
          {Routes}
        </section>
        <ToastsContainer store={ToastsStore} />
      </div>
    );
  }
}

App.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  language: PropTypes.string,
  user: PropTypes.object,
  navigation: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    language: state.app.language,
    user: state.app.user,
    navigation: state.app.navigation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    dispatch,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
