import { assign } from 'lodash';
import {
  GET_FACILITIES_SUCCESS,
  GET_FACTS_SUCCESS,
  GET_SECTIONS_SUCCESS,
  GET_PACKAGES_SUCCESS,
  GET_VOLUNTEERING_SUCCESS
} from './constants';
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [GET_FACILITIES_SUCCESS]: (state, action) => (
    assign({}, state, {
      facilities: action.facilities
    })
  ),
  [GET_FACTS_SUCCESS]: (state, action) => (
    assign({}, state, {
      facts: action.facts
    })
  ),
  [GET_SECTIONS_SUCCESS]: (state, action) => (
    assign({}, state, {
      sections: action.sections
    })
  ),
  [GET_PACKAGES_SUCCESS]: (state, action) => (
    assign({}, state, {
      packages: action.packages
    })
  ),
  [GET_VOLUNTEERING_SUCCESS]: (state, action) => (
    assign({}, state, {
      volunteering: action.volunteering
    })
  ),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  err: '',
  facilities: [],
  facts: [],
  sections: [],
  packages: [],
  volunteering: []
};

export default function home(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
