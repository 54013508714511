import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.scss';

const HeadOptions = (data) => {
  const {
    roles,
    options,
    path = '',
    customClass = '',
    actions
  } = data;

  const level = roles ? roles[0].value : 1;
  const nav = actions ? actions.map((option, i) => {
    return (
      <li key={`option-${i}`} className={'options__item'}>
        {option}
      </li>
    );
  })
    :
    options.map((option, i) => {
      if (level >= (option.restrictionLevel || 1)) {
        return (
          <li key={`option-${i}`} className={`options__item ${((path === option.path)) ? 'exact' : ''}`}>
            <NavLink
              className={`options__btn icon-${option.icon} ripple ${((path === option.path)) ? 'exact__active' : ''}`}
              to={option.path}
            >
              {option.name}
            </NavLink>
          </li>
        );
      }
      return null;
    });

  return (
    <div className={`options__container ${customClass}`}>
      <ul className={'options__list'}>
        {nav}
      </ul>
    </div>
  );
};

export default HeadOptions;
