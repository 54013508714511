export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('LMCCAPP');
    if (serializedState === null) {
      return undefined;
    }
    const state = JSON.parse(serializedState);
    delete state.router;
    // delete state.app.navigation;
    return state;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('LMCCAPP', serializedState);
  } catch (err) {
    // eslint-disable we need to handle error logs
  }
};
