import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { configureStore, history } from './store/configureStore';
import { loadState, saveState } from './store/localStorage';
import Root from './containers/Root';

import reportWebVitals from './reportWebVitals';

import 'styleRoot/base.scss';

const persistedState = loadState();
const store = configureStore(persistedState);

store.subscribe(() => {
  saveState(store.getState());
});

window.appStore = store;
render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById('root')
);

// if (module.hot) {
//   module.hot.accept('./containers/Root', () => {
//     const newConfigureStore = require('./store/configureStore'); // eslint-disable-line global-require
//     const NewRoot = require('./containers/Root').default; // eslint-disable-line global-require
//     const newStore = newConfigureStore.configureStore(persistedState);
//     const newHistory = newConfigureStore.history;
//     // const newStore = newConfigureStore.configureStore({}, newHistory);

//     render(
//       <AppContainer>
//         <div style={{ height: '100%' }}>
//           <NewRoot store={newStore} history={newHistory} />
//         </div>
//       </AppContainer>,
//       document.getElementById('app')
//     );
//   });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
