import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logout } from '../../containers/App/actions';
import Menu from '../Menu/Menu';
// import _ from 'lodash';
import './style.scss';

class User extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [
        <NavLink className="menu__btn icon-user3 ripple" key="profile" to={'/profile'}>Profile</NavLink>,
        <button className="menu__btn icon-exit ripple" key="logout" onClick={this.props.logout}>Logout</button>
      ]
    };

    autoBind(this);
  }

  getRoleStars(times) {
    const stars = [];
    for (let i = 0, max = times; i < max; i += 1) {
      stars.push(
        <li key={`role-${i}`} className={'user__roles__item icon-star-full2'}></li>
      );
    }
    return stars;
  }

  render() {
    const {
      customClass, user = {}, imageOnly, pickPhoto, showOptions, bg
    } = this.props;
    if (imageOnly) {
      return (
        <div className={`user__display ${customClass || ''} ${imageOnly ? 'imageOnly' : ''} ${user.picture ? 'inverted' : ''}`}>
          <div
            className={`user__image ${user.picture ? '' : `default ${bg ? 'icon-image' : 'icon-user3'}`}`}
            style={{ backgroundImage: `${user.picture ? `url(${user.picture})` : 'none'}` }}
          >
            {pickPhoto && <button onClick={pickPhoto} className="pick__photo__btn icon-camera"></button>}
          </div>
        </div>
      );
    }

    return (
      <div className={`user__display ${customClass || ''} ${user.picture ? 'inverted' : ''}`}>
        <div
          className={`user__image ${user.picture ? '' : 'default icon-user3'}`}
          style={{ backgroundImage: `${user.picture ? `url(${user.picture})` : 'none'}` }}
        >
          {pickPhoto && <button onClick={pickPhoto} className="pick__photo__btn icon-camera"></button>}
        </div>
        {user.name &&
          <div className={'user__data'}>
            <h2 className="user__name">{user.name}</h2>
            <ul className="user__roles__list">
              {this.getRoleStars(user.roles[0].value)}
            </ul>
          </div>
        }
        {showOptions &&
          <div className="user__menu__container">
            <Menu options={this.state.options} />
          </div>
        }
      </div>
    );
  }
}

User.propTypes = {
  customClass: PropTypes.string,
  user: PropTypes.object,
  showOptions: PropTypes.bool,
  imageOnly: PropTypes.bool,
  pickPhoto: PropTypes.func,
  logout: PropTypes.func,
  bg: PropTypes.bool,
};

const mapStateToProps = () => ({
  // logUser: state.app.user
});

const mapDispatchToProps = {
  logout
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
