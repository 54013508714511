import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import User from '../User';

import './style.scss';

class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    autoBind(this);
  }

  render() {
    const { navigation, user, companyIcon } = this.props;

    const navOptions = navigation.map((item, i) => {
      const userIsAllowed = isEmpty(user) ? false : user.roles[0].value >= item.restrictionLevel;

      if (userIsAllowed) {
        return (
          <li className={'main__nav__item'} key={`nav-option-${i}`}>
            <NavLink className={`main__nav__link ripple icon-${item.icon}`} to={item.path}>
              {item.name}
            </NavLink>
          </li>
        );
      }
      return null;
    });

    return (
      <nav className={'main__nav clear'}>
        <header className={'main__nav__header'}>
          {companyIcon && <NavLink className={`main__nav__company icon-${companyIcon}`} to={'/dashboard'}></NavLink>}
          <div className={'main__nav__user'}>
            <NavLink to={'/profile'}>
              <User user={user} customClass="inverted" imageOnly />
            </NavLink>
          </div>
        </header>
        <ul className={'main__nav__list clear'}>
          {navOptions}
        </ul>
      </nav>
    );
  }
}

Navigation.propTypes = {
  navigation: PropTypes.array,
  user: PropTypes.object,
  companyIcon: PropTypes.string
};

const mapStateToProps = (state) => ({
  navigation: state.app.navigation,
  user: state.app.user,
  companyIcon: state.app.companyIcon
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
