import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
// import MenuItem from '@material-ui/core/MenuItem';
import toRenderProps from 'recompose/toRenderProps';
import withState from 'recompose/withState';
import './style.scss';

const WithState = toRenderProps(withState('anchorEl', 'updateAnchorEl', null));

function RenderPropsMenu(props) {
  return (
    <WithState>
      {({ anchorEl, updateAnchorEl }) => {
        const open = Boolean(anchorEl);
        const handleClose = () => {
          updateAnchorEl(null);
        };

        return (
          <React.Fragment>
            {props.copy ?
              <Button
                aria-label="More"
                aria-owns={open ? 'menu' : undefined}
                aria-haspopup="true"
                onClick={(event) => {
                  updateAnchorEl(event.currentTarget);
                }}
              >
                {props.copy}
              </Button>
              :
              <IconButton
                aria-label="More"
                aria-owns={open ? 'menu' : undefined}
                aria-haspopup="true"
                onClick={(event) => {
                  updateAnchorEl(event.currentTarget);
                }}
              >
                <MoreVertIcon />
              </IconButton>
            }
            <Menu
              id="menu"
              className={`menu__list ${props.icons ? 'icons' : ''}`}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {props.options.map((el, i) => {
                return (
                  <li
                    className="menu__item"
                    key={`option-${i}`}
                    onClick={handleClose}
                    role="presentation"
                  >
                    {el}
                  </li>
                );
              })}
            </Menu>
          </React.Fragment>
        );
      }}
    </WithState>
  );
}

RenderPropsMenu.propTypes = {
  copy: PropTypes.string,
  options: PropTypes.array,
  icons: PropTypes.bool
};

export default RenderPropsMenu;
