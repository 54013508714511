import createHistory from 'history/createBrowserHistory';
import { applyMiddleware, createStore, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

export const history = createHistory();
const middleware = [
  thunk,
  routerMiddleware(history)
];

export function configureStore(initialState) {
  return createStore(
    rootReducer(history),
    initialState,
    compose(
      applyMiddleware(...middleware)
    )
  );
}
