import React from 'react';
import LoadingIndicator from '../../components/LoadingIndicator';

const MyAwesomeComponent = React.lazy(() => import('./index'));

const Loadable = (props) => (
  <React.Suspense fallback={<LoadingIndicator />}>
    <MyAwesomeComponent {...props} />
  </React.Suspense>
);

export default Loadable;
