// import { fromJS } from 'immutable';
import { assign } from 'lodash';
import { GET_ME_DATA_SUCCESS } from '../Profile/constants';
import { GENERAL_ERROR, SET_LANGUAGE, LOGOUT, LOGIN, LOGIN_MESSAGE, FS_UPDATE } from './constants';

const initialState = {
  err: '',
  user: {},
  loginMessage: '',
  token: '',
  languages: [
    { id: 'eng', name: 'English' },
    { id: 'esp', name: 'Español' }
  ],
  filestackApp: '',
  language: 'esp',
  companyIcon: 'lmcc',
  headOptions: [
    {
      name: 'personnel',
      path: '/personnel',
      icon: 'users4',
      restrictionLevel: 1,
      subNav: [
        {
          name: 'Users List',
          path: '/personnel',
          icon: 'users',
          restrictionLevel: 1
        },
        {
          name: 'Create User',
          path: '/personnel/create',
          icon: 'user-plus',
          restrictionLevel: 3
        }
      ]
    },
    {
      name: 'company',
      path: '/company',
      icon: 'briefcase',
      restrictionLevel: 3
    },
    {
      name: 'about',
      path: '/about',
      icon: 'profile',
      restrictionLevel: 3
    },
    {
      name: 'settings',
      path: '/settings',
      icon: 'wrench3',
      restrictionLevel: 4
    }
  ],
  navigation: [
    {
      name: 'dashboard',
      path: '/dashboard',
      icon: 'meter3',
      restrictionLevel: 2
    },
    {
      name: 'services',
      path: '/services',
      icon: 'clipboard5',
      restrictionLevel: 2
    },
    // {
    //   name: 'benefits',
    //   path: '/benefits',
    //   icon: 'furniture',
    //   restrictionLevel: 1
    // },
    {
      name: 'packages',
      path: '/packages',
      icon: 'package',
      restrictionLevel: 2
    },
    {
      name: 'facts',
      path: '/facts',
      icon: 'lamp6',
      restrictionLevel: 2
    },
    {
      name: 'volunteering',
      path: '/volunteering',
      icon: 'accessibility',
      restrictionLevel: 2
    },
    {
      name: 'facilities',
      path: '/facilities',
      icon: 'office',
      restrictionLevel: 2
    },
    {
      name: 'staff',
      path: '/staff',
      icon: 'collaboration',
      restrictionLevel: 2
    },
    {
      name: 'multi data',
      path: '/multi-data',
      icon: 'clipboard3',
      restrictionLevel: 2
    },
    {
      name: 'Headers',
      path: '/headers',
      icon: 'cube',
      restrictionLevel: 2
    }
  ]
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [GENERAL_ERROR]: (state, action) => (
    assign({}, state, {
      error: action.message,
    })
  ),
  [SET_LANGUAGE]: (state, action) => {
    return assign({}, state, {
      language: action.language,
    });
  },
  [LOGIN]: (state, action) => {
    return assign({}, state, {
      user: action.user,
      token: action.token,
      filestackApp: action.fsAppId,
      loginMessage: ''
    });
  },
  [FS_UPDATE]: (state, action) => {
    console.log('ACTION UPDATE = ', action);
    return assign({}, state, {
      filestackApp: action.fsAppId,
    });
  },
  [GET_ME_DATA_SUCCESS]: (state, action) => {
    return assign({}, state, {
      user: action.user,
      loginMessage: ''
    });
  },
  [LOGOUT]: (state) => {
    return assign({}, state, {
      user: {},
      token: '',
      loginMessage: ''
    });
  },
  [LOGIN_MESSAGE]: (state, action) => {
    return assign({}, state, {
      loginMessage: action.message
    });
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function app(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
