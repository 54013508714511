import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import User from '../User';
import HeadOptions from '../HeadOptions';
import './style.scss';

class SubNavigation extends React.Component {
  componentDidMount() {
  }

  render() {
    const { user, headOptions } = this.props;
    return (
      <nav className={'page__subnav clear'}>
        <HeadOptions roles={user.roles} options={headOptions} />
        <User user={user} customClass="user__header" showOptions />
      </nav>
    );
  }
}

SubNavigation.propTypes = {
  user: PropTypes.object,
  headOptions: PropTypes.array
};

const mapStateToProps = (state) => ({
  user: state.app.user,
  headOptions: state.app.headOptions
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SubNavigation);
